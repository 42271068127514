import { faHeart as faHeartLight } from '@fortawesome/pro-light-svg-icons/faHeart';
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons/faHeart';
import React, { CSSProperties, FunctionComponent, MouseEventHandler, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { FavouriteBox, Item } from './PropertyFavourite.style';

interface Props {
  containerStyle?: CSSProperties;
  favourited: boolean;
  onFavourite: (favourited: boolean) => Promise<boolean>;
  innerRef?: React.RefObject<HTMLButtonElement>;
}

const PropertyFavourite: FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const [playState, setPlayState] = useState<'running' | 'paused'>('paused');
  const [favourited, setFavourited] = useState<boolean>(props.favourited);

  const handleClick: MouseEventHandler = async (e) => {
    e.preventDefault();
    setFavourited(!favourited);
    setPlayState('running');

    setTimeout(() => {
      setPlayState('paused');
    }, 1000);

    const loggedIn = await props.onFavourite(!favourited);

    if (!loggedIn) {
      setFavourited(false);
    }
  };

  useEffect(() => {
    if (props.favourited !== favourited) {
      setFavourited(props.favourited);
    }
  }, [props.favourited]);

  return (
    <FavouriteBox
      ref={props.innerRef}
      style={props.containerStyle}
      playState={playState}
      onClick={handleClick}
      tabIndex={-1}
      className="hide-print pp-favourite-wrapper"
      data-testid="property-favourite-button"
      type="button"
      aria-label="Favourite property"
    >
      <Item opacity={1}>
        <FontAwesomeIcon icon={faHeartLight} size={24} className="pp-favourite-icon" />
      </Item>

      <Item opacity={favourited ? 1 : 0} data-testid="favourite-icon">
        <FontAwesomeIcon
          icon={faHeartSolid}
          size={26}
          style={{ marginLeft: -1, marginTop: -1, color: theme.primary }}
          className="pp-favourite-icon"
        />
      </Item>
    </FavouriteBox>
  );
};

export default PropertyFavourite;
