import { faBath } from '@fortawesome/pro-light-svg-icons/faBath';
import { faBedAlt } from '@fortawesome/pro-light-svg-icons/faBedAlt';
import { faCouch } from '@fortawesome/pro-light-svg-icons/faCouch';
import noPhoto from '@propertypal/shared/src/resources/fallbacks/no-photo-icon.png';
import { Property } from '@propertypal/shared/src/types/property';
import { PropertySearchResult } from '@propertypal/shared/src/types/propertySearch';
import { formatNumber } from '@propertypal/shared/src/utils/floatToCurrency';
import { getMainImage, getThumbImages, getAgentLogos } from '@propertypal/shared/src/utils/property';
import { SubHeading, Text } from '@propertypal/web-ui/src/typography';
import React, { FunctionComponent, useRef } from 'react';
import { useTheme } from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import LazyImage from '../image/LazyImage';
import StartEndTime from '../time/StartEndTime';
import EnergyRatingIcon from './EnergyRatingIcon';
import OpenViewingOverlay from './OpenViewingOverlay';
import PropertyBadge from './PropertyBadge';
import PropertyBanner from './PropertyBanner';
import {
  Container,
  Content,
  DescriptionBox,
  ExtraDescription,
  FavouriteBox,
  HeroNoImage,
  ImageContent,
  LinkContainer,
  SummaryRow,
  TextBox,
  ThumbImage,
  ThumbImageContainer,
  ViewsPill,
} from './PropertyBox.style';
import PropertyBoxFooter from './PropertyBoxFooter';
import PropertyDistance from './PropertyDistance';
import PropertyFavourite from './PropertyFavourite';
import PropertyPrice from './PropertyPrice';
import ShowHomesBox from './ShowHomesBox';

interface Props {
  property: Property;
  disablePremiumProperties?: boolean;
  onFavourite?: () => (favourited: boolean, propertyId: number) => Promise<boolean>;
  overlay?: React.ReactElement;
  hideFooter?: boolean;
  locationDetail?: PropertySearchResult['locationDetail'];
  imageLocator?: string;
  thumbImageLocator?: string;
  showAuctionsLabel?: boolean;
  showOpenViewingLabel?: boolean;
  useWideDisplay?: boolean;
  loading?: 'lazy' | 'eager';
  disableWideMode?: boolean;
  onClick?: () => void;
}

const PropertyBox: FunctionComponent<Props> = (props) => {
  const { property, overlay, hideFooter } = props;
  const isPremium = !props.disablePremiumProperties && props.property.premiumListing && !props.showOpenViewingLabel;
  const useWideDisplay = (props.useWideDisplay || isPremium) && !props.disableWideMode;
  const { srpBrandingBgColour } = props.property.enhancedBrandingColours || {};
  const theme = useTheme();
  const [heroImage, heroHash] = getMainImage(property, props.imageLocator);
  const thumbImages = getThumbImages(property, props.thumbImageLocator);
  const accountLogos = getAgentLogos(property, 'STANDARD');
  const showAuctionLabel = props.showAuctionsLabel && property.auctionTime;
  const showOpenViewingLabel = !isPremium && props.showOpenViewingLabel && property.openViewing;
  const showOpenViewingOverlay = !showOpenViewingLabel && property.openViewing && !property.openViewing.past;
  const descriptionBox = useRef<HTMLDivElement>(null);
  const canFavourite = props.onFavourite && property.type === 'property';

  return (
    <Container className="pp-property-box" $useWideDisplay={useWideDisplay}>
      {showAuctionLabel && property.listingTime?.type === 'auction' && property.listingTime.timeText && (
        <StartEndTime text={property.listingTime.timeText} />
      )}

      {showOpenViewingLabel && property.openViewing?.text && <StartEndTime text={property.openViewing.text} />}

      {!!props.property.distance && (
        <PropertyDistance distance={props.property.distance} locationDetail={props.locationDetail} />
      )}

      {overlay}

      <LinkContainer
        href={property.path}
        prefetch={false}
        onClick={props.onClick}
        $isPremium={isPremium}
        $premierColor={srpBrandingBgColour}
        $useWideDisplay={useWideDisplay}
      >
        <Content $useWideDisplay={useWideDisplay}>
          <ImageContent $useWideDisplay={useWideDisplay} $isPremium={isPremium}>
            {!heroImage && <HeroNoImage src={noPhoto.src} alt="No photo available" />}

            {!!heroImage && (
              <LazyImage
                src={heroImage}
                blurHash={heroHash}
                width={880}
                height={645}
                alt={`${property.displayAddress} photo`}
                loading={props.loading}
              />
            )}

            {!props.disablePremiumProperties && <PropertyBanner property={property} />}

            {!showOpenViewingOverlay && !!property.viewsInLast7Days && (
              <ViewsPill>
                <b>{formatNumber(property.viewsInLast7Days)}</b> views this week
              </ViewsPill>
            )}

            {/* Prevent auction badge from rendering when there is also an open viewing on property */}
            {/* Auction time is also disabled if the auction label is displayed at the top of the box instead */}
            {!showOpenViewingOverlay && <PropertyBadge property={property} showAuctionTime={!showAuctionLabel} />}

            {showOpenViewingOverlay && property.openViewing && (
              <OpenViewingOverlay
                openViewing={property.openViewing}
                themeName="dark"
                containerStyle={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              />
            )}
          </ImageContent>

          <DescriptionBox ref={descriptionBox} $useWideDisplay={useWideDisplay} $isPremium={isPremium}>
            {!props.disablePremiumProperties && isPremium && (
              <ThumbImageContainer $useWideDisplay={useWideDisplay}>
                {thumbImages &&
                  thumbImages.map((thumbImage, index) => (
                    <ThumbImage key={thumbImage.url}>
                      <LazyImage
                        src={thumbImage.url}
                        blurHash={thumbImage.blurHash}
                        alt={`${property.displayAddress} photo ${index + 2}`}
                        aria-hidden="true"
                        width={340}
                        height={255}
                        loading={props.loading}
                      />
                    </ThumbImage>
                  ))}
              </ThumbImageContainer>
            )}

            <TextBox>
              {canFavourite && (
                <FavouriteBox>
                  <PropertyFavourite
                    favourited={property.shortlisted}
                    // @ts-ignore
                    onFavourite={(favourited) => props.onFavourite()(favourited, property.id)}
                    // unique key needed in infinite grid, once item above is removed from list
                    // it would inherit its favourited state value in PropertyFavourite
                    key={property.id}
                  />
                </FavouriteBox>
              )}

              <PropertyPrice
                containerStyle={{ marginBottom: 12, fontWeight: 600, paddingRight: canFavourite ? 60 : 0 }}
                price={property.price}
                alternativeCurrencyPrice={property.alternativeCurrencyPrice}
                fontSize={34}
                status={property.status}
                comingSoonText={property.comingSoonText}
                prefixBreak
                primaryColor={isPremium ? props.property.enhancedBrandingColours?.pdpBrandingLinkColour : undefined}
              />

              <SubHeading
                color={theme.textMid}
                fontSize={16}
                mb={12}
                fontWeight="600"
                data-testid="property-box-address"
                style={{ maxWidth: 400 }}
              >
                {property.displayAddress.replace(/\s+/g, ' ')}
              </SubHeading>

              <Text fontSize={14} color={theme.textLight} mb={10}>
                {props.property.briefText}
              </Text>

              {isPremium && props.property.premierHighlight && (
                <ExtraDescription
                  data-testid="propertyDescription"
                  $color={
                    isPremium && props.property.enhancedBrandingColours
                      ? props.property.enhancedBrandingColours.pdpBrandingLinkColour
                      : theme.textDark
                  }
                >
                  {props.property.premierHighlight}
                </ExtraDescription>
              )}

              {isPremium && (
                <SummaryRow>
                  {!!property.numBedrooms && (
                    <>
                      <FontAwesomeIcon icon={faBedAlt} color={theme.textDark} size={22} />
                      <Text color={theme.textLight}>{property.numBedrooms}</Text>
                    </>
                  )}

                  {!!property.numBathrooms && (
                    <>
                      <FontAwesomeIcon icon={faBath} color={theme.textDark} size={22} />
                      <Text color={theme.textLight}>{property.numBathrooms}</Text>
                    </>
                  )}

                  {!!property.numReceptionRooms && (
                    <>
                      <FontAwesomeIcon icon={faCouch} color={theme.textDark} size={22} />
                      <Text color={theme.textLight}>{property.numReceptionRooms}</Text>
                    </>
                  )}

                  <EnergyRatingIcon containerStyle={{ marginBottom: 15 }} property={property} hideEpcText />
                </SummaryRow>
              )}

              <ShowHomesBox property={property} fontSize={14} />
            </TextBox>

            {!isPremium && !hideFooter && <PropertyBoxFooter key="1" property={property} logos={accountLogos} />}
          </DescriptionBox>
        </Content>

        {isPremium && !hideFooter && <PropertyBoxFooter key="2" property={property} premium logos={accountLogos} />}
      </LinkContainer>
    </Container>
  );
};

export default React.memo(PropertyBox);
