import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons/faMapMarkerAlt';
import grammarSchoolIcon from '@propertypal/shared/src/resources/icons/schools/grammar-school-icon.svg?url';
import integratedSchoolIcon from '@propertypal/shared/src/resources/icons/schools/integrated-college-icon.svg?url';
import primarySchoolIcon from '@propertypal/shared/src/resources/icons/schools/primary-school-icon.svg?url';
import roiPostPrimarySchoolIcon from '@propertypal/shared/src/resources/icons/schools/roi-post-primary-school-icon.svg?url';
import roiPrimarySchoolIcon from '@propertypal/shared/src/resources/icons/schools/roi-primary-school-icon.svg?url';
import secondarySchoolIcon from '@propertypal/shared/src/resources/icons/schools/secondary-school-icon.svg?url';
import { PropertySearchResult } from '@propertypal/shared/src/types/propertySearch';
import { Text } from '@propertypal/web-ui/src/typography';
import React, { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { Container } from './PropertyDistance.style';

interface Props {
  distance: string;
  locationDetail?: PropertySearchResult['locationDetail'];
}

interface ImageUrl {
  src: string;
  width: number;
  height: number;
}

const ICON_MAP: { [key: string]: ImageUrl } = {
  grammar: grammarSchoolIcon,
  secondary: secondarySchoolIcon,
  integrated: integratedSchoolIcon,
  primary: primarySchoolIcon,
  roiprimary: roiPrimarySchoolIcon,
  roipostprimary: roiPostPrimarySchoolIcon,
};

const getDistance = (distance: string): string[] => {
  if (distance.includes('k')) {
    return [distance.replace('k', ''), 'kilometres'];
  }

  return [distance.replace('m', ''), 'miles'];
};

const PropertyDistance: FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const [distance, measurement] = getDistance(props.distance);
  const schoolIcon = props.locationDetail?.school?.type ? ICON_MAP[props.locationDetail?.school?.type.key] : null;

  return (
    <Container>
      {props.locationDetail?.termType !== 'SCHOOL' && (
        <>
          <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: 5, fontSize: 14 }} color={theme.textDark} />

          <Text fontSize={12}>
            Distance from {props.locationDetail?.name}: <b>{distance}</b> {measurement}
          </Text>
        </>
      )}

      {props.locationDetail?.termType === 'SCHOOL' && schoolIcon && (
        <>
          <img src={schoolIcon.src} width={schoolIcon.width} height={schoolIcon.height} alt="school icon" />

          <Text fontSize={12}>
            Distance from School: <b>{distance}</b> {measurement}
          </Text>
        </>
      )}
    </Container>
  );
};

export default PropertyDistance;
