import { AgentLogoType, Property } from '@propertypal/shared/src/types/property';
import getSellingAgent from '@propertypal/shared/src/utils/property/getSellingAgent';
import React, { FunctionComponent } from 'react';
import { Text } from '../typography';
import { AgentLogo, Container, PremierContainer } from './PropertyBoxFooter.style';

interface Props {
  property: Property;
  premium?: boolean;
  logos?: AgentLogoType[];
}

const PropertyBoxFooter: FunctionComponent<Props> = (props) => {
  const sellingAgent = getSellingAgent(props.property);
  const { srpBrandingBgColour, srpBrandingTextColour } = props.property.enhancedBrandingColours || {};

  if (props.premium) {
    return (
      <PremierContainer color={srpBrandingBgColour}>
        {props?.logos &&
          props.logos?.length > 0 &&
          props.logos.map((logo) => (
            <AgentLogo
              src={logo.url}
              key={logo.url}
              alt={`${sellingAgent?.organisation} Logo`}
              aria-hidden="true"
              loading="lazy"
              width={logo.width}
              height={logo.height}
            />
          ))}

        {!!sellingAgent && (
          <Text fontSize={12} color={srpBrandingTextColour} style={{ flex: 1 }}>
            Marketed by {sellingAgent.organisation}
          </Text>
        )}
      </PremierContainer>
    );
  }

  const bgColor = props.property.enhancedBranding ? srpBrandingBgColour : undefined;
  const textColor = props.property.enhancedBranding ? srpBrandingTextColour : undefined;

  return (
    <Container data-testid="property-box-footer" color={bgColor}>
      {props?.logos &&
        props.logos?.length > 0 &&
        props.logos.map((logo) => (
          <AgentLogo
            src={logo.url}
            key={logo.url}
            alt={`${sellingAgent?.organisation} Logo`}
            aria-hidden="true"
            loading="lazy"
            width={logo.width}
            height={logo.height}
          />
        ))}

      {!!sellingAgent && (
        <div>
          <Text fontSize={12} color={textColor}>
            Marketed by
          </Text>
          <Text fontSize={12} color={textColor}>
            {sellingAgent.organisation}
          </Text>
        </div>
      )}
    </Container>
  );
};

export default PropertyBoxFooter;
