import { Property } from '@propertypal/shared/src/types/property';
import React, { CSSProperties, FunctionComponent } from 'react';
import CalendarIcon from '../calendar/CalendarIcon';
import { Container } from './ShowHomesBox.style';

interface Props {
  property: Property;
  containerStyle?: CSSProperties;
  fontSize?: number;
}

const ShowHomesBox: FunctionComponent<Props> = (props) => {
  if (!props.property.showHomeOpeningTime) {
    return null;
  }

  return (
    <Container className="pp-show-home-box" style={props.containerStyle}>
      {!!props.property.showHomeOpeningTime.date && (
        <CalendarIcon
          date={props.property.showHomeOpeningTime.date}
          mode="black"
          containerStyle={{ marginRight: 15 }}
        />
      )}

      <h4 style={{ fontSize: props.fontSize }}>
        <b>Show Home Open</b> <span>{props.property.showHomeOpeningTime.defaultText}</span>
      </h4>
    </Container>
  );
};

export default ShowHomesBox;
