import { mobileXXLargeMax, largeTabletMax } from '@propertypal/shared/src/constants/mediaQueries';
import Link from 'next/link';
import styled, { css } from 'styled-components';

const userSectionMax = 'max-width: 1024px';
const userSectionMin = 'min-width: 650px';

const userSectionQuery = `@media screen and (${userSectionMax}) and (${userSectionMin})`;

interface UseWideDisplay {
  $useWideDisplay?: boolean;
}

interface UserSection {
  $userSection?: boolean;
}

interface Loading {
  $isLoading?: boolean;
}

export const Container = styled.li<UseWideDisplay & UserSection & Loading>`
  position: relative;
  display: ${({ $userSection }) => ($userSection ? 'inline-' : '')}flex;
  flex-direction: column;

  ${({ $useWideDisplay }) =>
    $useWideDisplay &&
    css`
      grid-column: 1 / span 3;

      @media (${largeTabletMax}) {
        grid-column: 1 / span 2;
      }

      @media (${mobileXXLargeMax}) {
        grid-column: 1 / span 1;
      }
    `}
`;

export const LinkContainer = styled(Link)<Loading & UseWideDisplay & { $premierColor?: string; $isPremium?: boolean }>`
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: ${({ theme, $isLoading }) => ($isLoading ? 'transparent' : theme.background)};
  flex: 1;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: ${({ $useWideDisplay }) => ($useWideDisplay ? '0 10px 10px 0' : '0 0 10px 10px')};

  &:visited h2 {
    color: ${({ theme }) => theme.backgroundMid};
  }

  &:hover {
    background-color: ${({ $isLoading, theme }) => ($isLoading ? 'transparent' : theme.backgroundLighter)};
  }

  ${(props) =>
    props.$isPremium &&
    props.$premierColor &&
    `
    border: 3px solid ${props.$premierColor};
    border-radius: 10px;
  `}

  @media (${mobileXXLargeMax}) {
    // Fixes box height on iOS 10
    height: auto;
    border-radius: ${({ $isPremium }) => ($isPremium ? '10px' : '0 0 10px 10px')};
  }
`;

export const Content = styled.div<UseWideDisplay & UserSection>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  ${({ $userSection }) =>
    $userSection &&
    `      
      ${userSectionQuery} {
        flex-direction: row;
        flex-grow: 1;
        width: 100%;
      }
    `}

  ${({ $useWideDisplay }) =>
    $useWideDisplay &&
    css`
      flex-direction: row;

      @media (${mobileXXLargeMax}) {
        flex-direction: column;
      }
    `}
`;

const MobImageContent = css`
  flex-basis: initial;
  width: 100%;
  max-width: none;
`;

export const ImageContent = styled.div<UseWideDisplay & UserSection & { $isPremium?: boolean }>`
  position: relative;
  overflow: hidden;

  ${({ $userSection }) =>
    $userSection &&
    `      
      ${userSectionQuery} {
        max-width: 380px;
      }
    `}

  ${({ $useWideDisplay, $isPremium }) =>
    $useWideDisplay &&
    css`
      flex-basis: ${$isPremium ? '50%' : '40%'};

      @media (${mobileXXLargeMax}) {
        ${MobImageContent}
      }
    `}
`;

export const FavouriteBox = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ViewsPill = styled.p`
  padding: 4px 10px;
  background-color: ${({ theme }) => theme.white};
  border-radius: 20px;
  color: ${({ theme }) => theme.textDark};
  position: absolute;
  bottom: 8px;
  left: 8px;
  z-index: 2;
  font-size: 14px;
`;

const MobThumbImageContainer = css`
  margin: 10px 0 0;
`;

export const ThumbImageContainer = styled.div<{ $useWideDisplay?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin: 10px 0 0;

  ${({ $useWideDisplay }) =>
    $useWideDisplay &&
    css`
      margin: 0 10px 10px 10px;

      @media (${mobileXXLargeMax}) {
        ${MobThumbImageContainer}
      }
    `}
`;

export const ThumbImage = styled.div`
  width: 32%;
`;

export const DescriptionBox = styled.div<{ $useWideDisplay?: boolean; $isPremium?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: ${({ $isPremium, $useWideDisplay }) => ($isPremium && $useWideDisplay ? 'column-reverse' : 'column')};
  justify-content: space-between;

  @media (${mobileXXLargeMax}) {
    flex-direction: column;
  }
`;

const MobTextBox = css`
  margin-top: 15px;
`;

export const TextBox = styled.div`
  position: relative;
  margin: 15px;

  @media (${mobileXXLargeMax}) {
    ${MobTextBox}
  }
`;

export const ExtraDescription = styled.p<{ $color: string }>`
  background-color: ${({ theme }) => theme.backgroundLighter};
  font-weight: bold;
  display: inline-block;
  padding: 4px 6px;
  margin: 20px 0;
  border-radius: 5px;
  font-size: 14px;
  color: ${({ $color }) => $color};
  text-transform: uppercase;
`;

export const SummaryRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 15px 0 0;

  & > svg {
    color: ${({ theme }) => theme.textLight};
    margin: 0 10px 15px 0;
  }

  & > p {
    margin: 0 20px 15px 0;
  }
`;

export const HeroNoImage = styled.img`
  width: 100%;
  background-color: ${(props) => props.theme.backgroundLighter};
`;
