import { mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';

export const AgentLogo = styled.img`
  max-width: 31%;
  max-height: 42px;
  width: auto;
  height: auto;
`;

export const PremierContainer = styled.div<{ color?: string }>`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 70px;
  background-color: ${(props) => props.color || props.theme.primary};
  gap: 15px;

  p {
    text-align: right;
  }

  @media (${mobileXXLargeMax}) {
    p {
      text-align: left;
    }
  }
`;

export const Container = styled.div<{ color?: string }>`
  background-color: ${({ color }) => color || 'none'};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 15px;
`;
