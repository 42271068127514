import styled from 'styled-components';

export const Container = styled.div<{ themeName: string }>`
  background-color: ${(props) => (props.themeName === 'dark' ? 'rgba(0,0,0,0.3)' : props.theme.backgroundLighter)};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-radius: ${(props) => (props.themeName === 'dark' ? 0 : 8)}px;
  width: 100%;
  z-index: 2;
`;

export const CalendarBox = styled.div<{ cancelled: boolean }>`
  position: relative;
  display: flex;
  margin-right: 10px;

  ${({ theme, cancelled }) =>
    cancelled
      ? `
    &::before {
      border-top: solid 3px ${theme.primary};
      content: '';
      position: absolute;
      left: 0px;
      right: -14px;
      bottom: 0px;
      z-index: 1;
      transform: rotate(-40deg);
      transform-origin: 0% 0%;
    }
  `
      : ``}
`;
