import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import React from 'react';
import { useTheme } from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { Box } from '../layout';
import { Text } from '../typography';
import { Container } from './StartEndTime.style';

interface StartEndTimeProps {
  text: string;
}

const StartEndTime: React.FC<StartEndTimeProps> = (props) => {
  const theme = useTheme();

  return (
    <Container data-testid="start-end-time">
      <Box flexBasis="16px" mr={10}>
        <FontAwesomeIcon icon={faClock} data-testid="clock-icon" color={theme.textDark} />
      </Box>

      <Text lineHeight={24} fontWeight="bold" data-testid="date-text" color={theme.textDark}>
        {props.text}
      </Text>
    </Container>
  );
};

export default StartEndTime;
