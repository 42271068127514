import Calendar from '@propertypal/shared/src/resources/icons/open-viewing/calendar-icon.svg';
import styled from 'styled-components';
import { CalendarIconProps } from './CalendarIcon';

interface Mode {
  mode: CalendarIconProps['mode'];
}

export const Container = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
`;

export const Icon = styled(Calendar)<Mode>`
  width: 48px;
  height: 48px;

  path {
    fill: ${({ theme, mode }) => theme[mode === 'white' ? 'white' : 'textDark']};
  }
`;

export const CalendarMonth = styled.span<Mode>`
  position: absolute;
  right: 0;
  left: 0;
  text-align: center;
  width: 100%;
  top: 7px;
  font-size: 0.6em;
  line-height: 12px;
  color: ${({ theme, mode }) => theme[mode === 'white' ? 'textDark' : 'white']};
  text-transform: uppercase;
  font-weight: 600;
`;

export const CalendarDay = styled.span<Mode>`
  position: absolute;
  right: 0;
  left: 0;
  text-align: center;
  width: 100%;
  bottom: 6px;
  font-size: 1.3em;
  color: ${({ theme, mode }) => theme[mode === 'white' ? 'white' : 'textDark']};
  text-transform: uppercase;
  font-weight: 600;
`;
