import { BLUE, GREEN, GREY_300, PLUM, RED, TOMATO_RED } from '@propertypal/shared/src/styles/theme';
import styled from 'styled-components';

const COLOR_MAP: { [key: string]: string } = {
  comingSoon: PLUM,
  newPhase: GREEN,
  newPhaseComingSoon: GREEN,
  featured: TOMATO_RED,
  futureDevelopment: BLUE,
  lastRemainingProperty: RED,
  lastFew: RED,
  premier: GREY_300,
};

export const Container = styled.div<{ type: string }>`
  background-color: ${(props) => (COLOR_MAP[props.type] ? COLOR_MAP[props.type] : props.theme.primary)};
  width: 160px;
  height: 32px;
  padding: 0 25px 0 20px;
  justify-content: center;
  transform: rotate(-45deg) translate(-44px, -6px);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 2;

  @media print {
    display: none;
  }
`;

export const BannerText = styled.p`
  color: ${(props) => props.theme.white};
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 12px;
`;
