import { parseApiDate } from '@propertypal/shared/src/services/dayjs';
import React, { CSSProperties, useRef } from 'react';
import { CalendarDay, CalendarMonth, Container, Icon } from './CalenderIcon.style';

export interface CalendarIconProps {
  date: string;
  mode: 'black' | 'white';
  containerStyle?: CSSProperties;
}

const CalendarIcon: React.FC<CalendarIconProps> = (props) => {
  const date = useRef(parseApiDate(props.date));

  return (
    <Container className="pp-calendar-icon" style={props.containerStyle}>
      <Icon mode={props.mode} />
      <CalendarMonth mode={props.mode} className="pp-calendar-icon-month">
        {date.current.format('MMM')}
      </CalendarMonth>
      <CalendarDay mode={props.mode} className="pp-calendar-icon-day">
        {date.current.format('DD')}
      </CalendarDay>
    </Container>
  );
};

export default CalendarIcon;
