import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.theme.backgroundLightest};
  padding: 3px 10px;

  svg {
    margin-right: 5px;
  }
`;

export default Container;
