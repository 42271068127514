import styled from 'styled-components';

export const Container = styled.div<{ useSecondaryColor?: boolean }>`
  padding: 2px 6px 3px;
  background-color: ${(props) => (props.useSecondaryColor ? props.theme.secondary : props.theme.primary)};
  border-radius: 2px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 2;
`;

export const BadgeText = styled.p`
  font-weight: 600;
  color: ${(props) => props.theme.white};
  font-size: 11px;
`;
