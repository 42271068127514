import { decode } from 'blurhash';
import React, { FunctionComponent, useRef } from 'react';
import { BlurredImage, Container, Image } from './LazyImage.style';

interface Props {
  src: string;
  blurHash?: string;
  width: number;
  height: number;
  alt?: string;
  loading?: 'lazy' | 'eager';
}

const BLUR_WIDTH = 10;
const BLUR_HEIGHT = 10;

const blurHashToCss = (hash: string) => {
  const pixels = decode(hash, BLUR_WIDTH, BLUR_HEIGHT);
  const gradients = [];

  for (let y = 0; y < BLUR_HEIGHT; y += 1) {
    const row = [];

    for (let x = 0; x < BLUR_WIDTH; x += 1) {
      const index = x * 4 + y * 4 * BLUR_WIDTH;
      let gradient = `rgb(${pixels[index]},${pixels[index + 1]},${pixels[index + 2]})`;

      if (x !== 0) gradient += ` ${Math.round((x / BLUR_WIDTH) * 100)}%`;
      gradient += ` ${Math.round(((x + 1) / BLUR_WIDTH) * 100)}%`;

      row.push(gradient);
    }

    gradients.push(`linear-gradient(90deg, ${row.join(', ')})`);
  }

  return `
    background-image: ${gradients.join(', ')};
    background-position: '0 0,0 11%,0 22%,0 33%,0 44%,0 56%,0 67%,0 78%,0 89%,0 100%';
    background-repeat: 'no-repeat';
    background-size: '100% 10%';
  `;
};

const LazyImage: FunctionComponent<Props> = (props) => {
  const blurCss = props.blurHash ? blurHashToCss(props.blurHash) : undefined;
  const loading = props.loading || 'lazy';
  const imageRef = useRef<HTMLImageElement>(null);

  return (
    <Container>
      {blurCss && <BlurredImage blurCss={blurCss} />}

      <Image
        ref={imageRef}
        src={props.src}
        loading={loading}
        alt={props.alt}
        width={props.width}
        height={props.height}
      />
    </Container>
  );
};

export default React.memo(LazyImage);
