import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${(props) => props.theme.backgroundLightest};
  padding: 15px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  h4 {
    color: ${(props) => props.theme.textDark};
    font-size: 18px;

    span {
      font-weight: normal;
      display: block;
    }
  }
`;

export default Container;
