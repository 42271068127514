import currency from 'currency.js';

/**
 * Convert float numbers to currency display strings
 */
export interface ConverterOptions {
  symbol?: string;
  precision?: number;
  currencyCode?: string;
  negativePattern?: string;
  pattern?: string;
}

const defaultOptions = {
  increment: 0,
  precision: 2,
  symbol: '£',
  currencyCode: 'GBP',
  negativePattern: '-!#',
  pattern: '!#',
};

export const parseNumber = (number: string | number): number => {
  if (typeof number === 'number') {
    return number;
  }

  if (number.trim() === '') return 0;

  return parseFloat(number.replace(/,/g, ''));
};

export const formatNumber = (number: string | number | undefined): string => {
  if (number === undefined) {
    return '';
  }

  return String(number)
    .replace(/,/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const floatToCurrency = (n?: number, options: ConverterOptions = {}): string => {
  options = { ...defaultOptions, ...options };

  return currency(n || 0, options).format();
};

export default floatToCurrency;
