import { parseApiDate } from '@propertypal/shared/src/services/dayjs';
import { OpenViewing } from '@propertypal/shared/src/types';
import React, { CSSProperties } from 'react';
import { useTheme } from 'styled-components';
import CalendarIcon from '../calendar/CalendarIcon';
import { Text } from '../typography';
import { Container, CalendarBox } from './OpenViewingOverlay.style';

interface Props {
  containerStyle?: CSSProperties;
  openViewing: OpenViewing;
  themeName: 'light' | 'dark';
}

const OpenViewingOverlay: React.FC<Props> = ({ openViewing, themeName, containerStyle }) => {
  const theme = useTheme();
  const textColor = themeName === 'light' ? theme.textDark : theme.white;

  const dateText = () => {
    if (openViewing.cancelled) return '';
    const date = parseApiDate(openViewing.startTime);
    if (date.isToday()) return 'Today';
    if (date.isTomorrow()) return 'Tomorrow';
    return openViewing.dayText;
  };

  return (
    <Container themeName={themeName} data-testid="open-viewing-overlay" style={containerStyle}>
      <CalendarBox cancelled={openViewing.cancelled}>
        <CalendarIcon mode={themeName === 'light' ? 'black' : 'white'} date={openViewing.startTime} />
      </CalendarBox>

      <div>
        <Text color={textColor} fontWeight="bold" fontSize={16} data-testid="date-text">
          Open Viewing {dateText()}
        </Text>

        <Text
          color={textColor}
          fontSize={16}
          fontWeight={openViewing.cancelled ? 'bold' : 'normal'}
          data-testid="date-time"
        >
          {openViewing.cancelled ? `CANCELLED` : `Open from ${openViewing.timeText}`}
        </Text>
      </div>
    </Container>
  );
};

export default OpenViewingOverlay;
