import { Agent, Property } from '../../types/property';

const getSellingAgent = (property: Property): Agent | undefined => {
  if (property.contacts?.default) {
    return {
      ...property.contacts.default,
      logo: property.contacts.default.logos,
    };
  }

  const agents = property.contacts?.all.filter((c) => !c.developer);

  if (agents?.length === 1) {
    return {
      ...agents[0],
      logo: agents[0].logos,
    };
  }

  if (property.agents?.length === 1) {
    return property.agents[0];
  }

  return undefined;
};

export const getAgentColours = (agent?: Agent) => {
  if (agent?.enhancedBranding && agent?.colours) {
    return agent.colours;
  }

  return {
    pdpBrandingBgColour: '',
    pdpBrandingBorderColour: '',
    pdpBrandingButtonBgColour: '',
    pdpBrandingButtonTextColour: '',
    pdpBrandingLinkColour: '',
    srpBrandingBgColour: '',
    srpBrandingTextColour: '',
  };
};

export default getSellingAgent;
