import { Property } from '@propertypal/shared/src/types/property';
import puralise from '@propertypal/shared/src/utils/puralise';
import React, { CSSProperties } from 'react';
import { BadgeText, Container } from './PropertyBadge.style';

interface Props {
  property: Property;
  containerStyle?: CSSProperties;
  showAuctionTime?: boolean;
}

const PropertyBadge = (props: Props) => {
  const { listingTime, totalAvailableUnits } = props.property;

  if (!props.showAuctionTime && listingTime?.type === 'auction') {
    return null;
  }

  if (totalAvailableUnits !== undefined && totalAvailableUnits > 0) {
    return (
      <Container className="pp-property-box-badge" style={props.containerStyle}>
        <BadgeText data-testid="badgeText">
          {totalAvailableUnits} {puralise('home', 'homes', totalAvailableUnits)} available
        </BadgeText>
      </Container>
    );
  }

  if (listingTime && listingTime.typeText && listingTime.timeText && listingTime.type) {
    return (
      <Container
        className="pp-property-box-badge"
        useSecondaryColor={listingTime.type === 'auction'}
        style={props.containerStyle}
      >
        <BadgeText data-testid="badgeText">
          {listingTime.typeText} {listingTime.timeText}
        </BadgeText>
      </Container>
    );
  }

  return null;
};

export default PropertyBadge;
