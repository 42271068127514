import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% { 
    transform: scale(1); 
  }
  33% { 
    transform: scale(0.75);
  }
  66% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export const FavouriteBox = styled.button<{ playState: 'paused' | 'running' }>`
  position: relative;
  animation-name: ${pulse};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-play-state: ${(props) => props.playState};
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.primaryTranslucent};
  border-radius: 20px;
  color: ${({ theme }) => theme.textDark};
`;

export const Item = styled.div<{ opacity: number }>`
  position: absolute;
  top: 9px;
  left: 8px;
  opacity: ${(props) => props.opacity};
  transition: 0.5s opacity;
  transition-delay: ${(props) => (props.opacity === 0 ? 0 : 0.5)}s;
`;
